import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const listAll = async () => {
  // var axios = require('axios');

  // const { token } = '987uyhgbv124q3wFDGSWE43reF545ETGHFDwae4a5iIKUYH5';
  // //const auth = getAuth();
  // var config = {
  //   method: 'get',
  //   url: `${CONFIG.API_URL_MANAGER}/tarifaResolucao/listAll`,
  //   headers: {
  //     Bearer: token
  //   }
  // };

  // return axios(config)
  //   .then(function (response) {
  //     //console.log(response);
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     //console.log(error);
  //   });
};
export const listByResolucao = async (resolucao) => {
  var axios = require('axios');
  var data = { resolucao };

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_MANAGER}/tarifaResolucaoItem/listByResolucao`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      Vue.$toast.success('Dados encontrados.', response.content);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
};
