<template>
  <div>
    <!-- Breadcrumb -->
    <PageHeader :items="breadcrumb" />
    <b-card class="mb-3 mt-3">
      <b-container fluid>
        <b-row>
          <b-col>
            <h4 class="text-center">Tarifas Resolução</h4>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th
                      rowspan="3"
                      style="vertical-align: middle"
                      class="bg-custom"
                    >
                      ID
                    </th>
                    <th
                      rowspan="3"
                      style="vertical-align: middle"
                      class="bg-custom"
                    >
                      Subgrupo
                    </th>
                    <th
                      rowspan="3"
                      style="vertical-align: middle"
                      class="bg-custom"
                    >
                      Modalidade
                    </th>
                    <th
                      rowspan="3"
                      style="vertical-align: middle"
                      class="bg-custom"
                    >
                      Acessante/Classe
                    </th>
                    <th
                      rowspan="3"
                      style="vertical-align: middle"
                      class="bg-custom"
                    >
                      Subclasse
                    </th>
                    <th
                      rowspan="3"
                      style="vertical-align: middle"
                      class="bg-custom"
                    >
                      Posto
                    </th>
                    <th
                      colspan="3"
                      class="text-center"
                      style="background-color: #f2f2f2"
                    >
                      Tarifa Aplicação
                    </th>
                    <th
                      colspan="3"
                      class="text-center"
                      style="background-color: #f2f2f2"
                    >
                      Base Econômica
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2" class="bg-tarifa-aplicacao text-center">
                      TUSD
                    </th>
                    <th colspan="1" class="bg-tarifa-aplicacao text-center">
                      TE
                    </th>
                    <th colspan="2" class="bg-base-economica text-center">
                      TUSD
                    </th>
                    <th colspan="1" class="bg-base-economica text-center">
                      TE
                    </th>
                  </tr>
                  <tr>
                    <th class="bg-custom">R$/kW</th>
                    <th class="bg-custom">R$/MWh</th>
                    <th class="bg-custom">R$/MWh</th>
                    <th class="bg-custom">R$/kW</th>
                    <th class="bg-custom">R$/MWh</th>
                    <th class="bg-custom">R$/MWh</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(tarifa, index) in tarifas"
                    :key="index"
                    :class="{ 'table-row-grey': index % 2 != 0 }"
                  >
                    <td>{{ tarifa.resolucao.id }}</td>
                    <td>{{ tarifa.subgrupo }}</td>
                    <td>{{ tarifa.modalidade }}</td>
                    <td>{{ tarifa.acessanteClasse }}</td>
                    <td>{{ tarifa.subclasse }}</td>
                    <td>{{ tarifa.posto }}</td>
                    <td>{{ formatarValor(tarifa.tarifaAplicacaoTUSDkw) }}</td>
                    <td>{{ formatarValor(tarifa.tarifaAplicacaoTUSDmwh) }}</td>
                    <td>{{ formatarValor(tarifa.tarifaAplicacaoTEmwh) }}</td>
                    <td>{{ formatarValor(tarifa.baseEconomicaTUSDkw) }}</td>
                    <td>{{ formatarValor(tarifa.baseEconomicaTUSDmwh) }}</td>
                    <td>{{ formatarValor(tarifa.baseEconomicaTEmwh) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Encargos -->
    <b-card v-if="tarifas.encargoCovid || tarifas.tarifaEscassez">
      <b-container fluid>
        <b-row>
          <b-col>
            <h4 class="text-center">Encargos</h4>
            <b-list-group>
              <b-list-group-item v-if="tarifas.encargoCovid">
                <strong>Encargo COVID (R$/MWh):</strong>
                {{ tarifas.encargoCovid }}
              </b-list-group-item>
              <b-list-group-item v-if="tarifas.tarifaEscassez">
                <strong>Encargo Escassez Hídrica (R$/MWh):</strong>
                {{ tarifas.tarifaEscassez }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { listByResolucao } from './tarifaResolucao.service';
import PageHeader from '@/components/page-header';

export default {
  data() {
    return {
      breadcrumb: [
        {
          text: 'Tarifa Resolução',
          href: '#'
        },
        {
          text: 'Itens',
          active: true
        }
      ],
      tarifas: [],
      resolucao: {}
    };
  },
  components: {
    PageHeader
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('pt-BR');
    },
    buscarTarifas() {
      listByResolucao(this.resolucao)
        .then((response) => {
          this.tarifas = response.content;
        })
        .catch((error) => {
          console.error('Erro ao buscar tarifas:', error);
        });
    },
    formatarValor(valor) {
      if (typeof valor === 'number') {
        // Formatar o valor substituindo o ponto pela vírgula
        return valor.toFixed(2).replace('.', ',');
      }
      return valor;
    }
  },
  mounted() {
    this.buscarTarifas();
  },
  created() {
    const data = this.$route.query.data;
    if (data != null) {
      try {
        this.resolucao = JSON.parse(data);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>
.filter-select {
  width: 35%;
  margin-left: 10px;
}
.text-center {
  text-align: center;
}
.bg-tarifa-aplicacao {
  background-color: #f47721;
  color: white;
}
.bg-base-economica {
  background-color: #00a94f;
  color: white;
}

.table-row-grey {
  background-color: #f2f2f2;
}

.table td,
.table th {
  padding: 0.25rem;
}

.bg-custom {
  background-color: #f2f2f2;
}
</style>
